/* COVER: Escolha de Canal */
#Index_index.cover_nochannel #gridChannel {
    margin: 60px auto 60px;
}
@media (max-width: 1180px) {
    #Index_index.cover_nochannel #gridChannel {
        margin: 40px auto 50px;
    }
}
@media (max-width: 1179px) {
    #Index_index.cover_nochannel #gridChannel {
        margin: 40px auto 40px;
    }
}
@media (max-width: 769px) {
    #Index_index.cover_nochannel #gridChannel {
        margin: 30px auto 50px;
    }
}

#Index_index.cover_pbshop #mainCarousel {
    margin-top: 20px;
    margin-bottom: 60px;
}
#Index_index.cover_pbshop #materialCarousel {
    margin-bottom: 46px;
}
#Index_index.cover_pbshop #highlights,
#Index_index.cover_pbshop #parcerias,
#Index_index.cover_pbshop #lojaspbshop,
#Index_index.cover_pbshop #archtrends,
#Index_index.cover_pbshop #fullbanner,
#Index_index.cover_pbshop #searchExtras {
    margin-bottom: 60px;
}
@media (max-width: 1400px) {
    #Index_index.cover_pbshop #mainCarouse,
    #Index_index.cover_pbshop #materialCarousel,
    #Index_index.cover_pbshop #highlights,
    #Index_index.cover_pbshop #parcerias,
    #Index_index.cover_pbshop #lojaspbshop,
    #Index_index.cover_pbshop #archtrends,
    #Index_index.cover_pbshop #fullbanner,
    #Index_index.cover_pbshop #searchExtras {
        margin-bottom: 40px;
    }    
}
@media (max-width: 1200px) {
    #Index_index.cover_pbshop #mainCarousel,
    #Index_index.cover_pbshop #materialCarousel,
    #Index_index.cover_pbshop #highlights,
    #Index_index.cover_pbshop #parcerias,
    #Index_index.cover_pbshop #lojaspbshop,
    #Index_index.cover_pbshop #archtrends,
    #Index_index.cover_pbshop #fullbanner,
    #Index_index.cover_pbshop #searchExtras {
        margin-bottom: 30px;
    }    
}
@media (max-width: 769px) {
    #Index_index.cover_pbshop #mainCarousel,
    #Index_index.cover_pbshop #materialCarousel,
    #Index_index.cover_pbshop #highlights,
    #Index_index.cover_pbshop #parcerias,
    #Index_index.cover_pbshop #lojaspbshop,
    #Index_index.cover_pbshop #archtrends,
    #Index_index.cover_pbshop #fullbanner,
    #Index_index.cover_pbshop #searchExtras {
        margin-bottom: 40px;
    }    
}
/* Capa do Canal */
#Index_index #component_135 {
    padding-top: 40px;
}
@media (max-width: 769px) {    
    #Index_index #component_135 {
        padding-top: 0px;
    }
}

/* LISTAGEM DE LINHAS (lines-v2.tpl)*/
#Line_index #breadcrumbs, 
#Product_lastrasProducts #breadcrumbs {
    padding: 40px 0 10px;
}
#Line_index #header {
    padding-top: 8px;
    margin-bottom: 40px;
}
#Line_index #filtersContainer {
    margin-bottom: 40px;
}
#Line_index #seocontent {
    margin-top: 60px;
}
#Line_index section:has(.pb23-carousel--content) + .pb23-divider:has(+ #seocontent) {
    display: none;
}
#Line_index > footer {
    margin-top: 60px;
}
#Line_index #listlines + #content:has(.pb23-carousel--content) {
    margin-top: 60px;
}
@media (max-width: 769px) {
    #Line_index #filtersContainer {
        margin-bottom: 60px;
    }
}
@media (max-width: 769px) {
    #Line_index #seocontent {
        margin-top: 40px;
    }
    #Line_index #filtersContainer {
        margin-bottom: 20px;
    }
    #Line_index #listlines + #content:has(.pb23-carousel--content) {
        margin-top: 40px;
    }
    #Line_index > footer {
        margin-top: 40px;
    }
}
/* PÁGINA DA LINHA */
#Line_view #breadcrumbs {
    padding: 40px 0 10px;
}
#Line_view #header {
    padding: 0px 0 40px;
}
#Line_view .pb23-divider {
    margin: 60px 0;
}
#Line_view #findstore {
    margin-bottom: 60px;
}
#Line_view #archtrends-masonry {
    margin: 0 0 30px;
}
#Line_view #archtrends-masonry .pb23-section--header h2 {
    /*border-top: 1px solid var(--clr-gray-100);*/
    margin-top: 60px;
/*    padding-top: 60px;*/
}
#Line_view section:has(.pb23-carousel--content) + .pb23-divider:has(+ #seocontent) {
    display: none;
}
#Line_view #seocontent + footer {
    margin-top: 60px;
}
#Line_view section[id] {
    scroll-margin-top: var(--menu-height);
}
@media (max-width: 769px) {
    #Line_view #header {
        padding: 0;
        margin-bottom: 0;
    }
    #Line_view .pb23-divider {
       margin: 40px 0;
   }
   #Line_view #archtrends-masonry .pb23-section--header h2 {
        margin-top: 40px;
        padding-top: 40px;
    }
   #Line_view #findstore {
       margin-bottom: 40px;
   }
   #Line_view #seocontent + footer {
       margin-top: 40px;
   }
}
#Product_groupcolor #findstore {
    padding-bottom: 60px;
}
/* LASTRAS */
#Product_lastrasProducts #header {
    padding-top: 8px;
}
#Product_lastrasProducts .pb23-header--content {
    margin-bottom: 40px;
}
#Product_lastrasProducts #filtersContainer {
    margin-bottom: 40px;
}
#Product_lastrasProducts > .pb23-divider {
    display: none;
}


/* SIMULADOR DE MOSAICOS */
/* Capa do Simulador */
body:has(#simuladormosaicosCover) {
    padding: 0;
}
#simuladormosaicosCover {
    width: 100dvw;
    height: calc(100dvh - var(--menutool-height));
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-template-areas: "image content";
    gap: 180px;
}
#simuladormosaicosCover .image--container {
    grid-area: image;
    width: 100%;
    height: calc(100dvh - var(--menutool-height));
}
#simuladormosaicosCover .image--container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
#simuladormosaicosCover .content--container {
    max-width: 36dvw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 80px;
}
#simuladormosaicosCover .content--container .header--wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
}
#simuladormosaicosCover .content--container .header--wrapper header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    margin: 0;
}
#simuladormosaicosCover .content--container .header--wrapper header h1 {
    order: 2;
    margin: 0;
    font-size: 3rem;
    font-weight: 500;
}
#simuladormosaicosCover .content--container .header--wrapper header span {
    order: 1;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 2px;
}
#simuladormosaicosCover .content--container .content--wrapper p {
    font-weight: 300;
}
@media (max-width: 1440px) {
    #simuladormosaicosCover {
        gap: 78px;
    }
    #simuladormosaicosCover .content--container {
        max-width: 45dvw;
        gap: 40px;
    }
}
@media (max-width: 1180px) {
    #simuladormosaicosCover {
        min-height: auto;
        height: auto;
        grid-template-columns: 100%;
        grid-template-areas: "image" "content";
        grid-template-rows: 33dvh auto;
        gap: 160px;
        padding: 0 0 calc(2 * var(--menutool-height));
    }
    #simuladormosaicosCover .content--container {
        max-width: 50dvw;
        margin: 0 auto;
        justify-content: flex-start;
        gap: 40px;
    }
    #simuladormosaicosCover .image--container {
        height: 100%;
    }
}
@media (max-width: 1024px) {
    #simuladormosaicosCover {
        grid-template-rows: 45dvh auto;
        gap: 50px;
    }
}
@media (max-width: 769px) {
    #simuladormosaicosCover .content--container {
        max-width: calc(100dvh - 40px);
        padding: 0 20px;
    }
    #simuladormosaicosCover .content--container .header--wrapper {
        gap: 20px;
    }
    #simuladormosaicosCover .content--container .header--wrapper header span {
        font-size: 0.875rem;
    }
    #simuladormosaicosCover .content--container .header--wrapper header h1 {
        font-size: 1.75rem;
    }
}
#Mosaico_mosaicsimulatorLines {
    padding-top: 60px;
}
#Mosaico_mosaicsimulatorLines .pb23-header--tool {
    margin: 0 0 40px;
}
#Mosaico_mosaicsimulatorLines .pb23-list--grid.linesTool {
    padding: 0 0 60px;
}

body[id^="Product_downloads"] .pb23-breadcrumbs {
    padding: 40px 0 10px;
} 
body[id^="Product_downloads"] .info {
    text-align: center;
}
/* Oportunidades Black Days */
#lp-blackdays-2024 #pb23-fixedTag .pb23-btn.dark,
#lp-blackdays-2024 #pb23-fixedTag .pb23-btn.dark * {
    --button-font: 0.875rem;
    --button-weight: 500;
    --button-transform: normal; 
    --button-letter: 0px;
    --button-bg: var(--clr-promo02-300);
    --button-color--hover: var(--clr-black);
    --button-color: var(--clr-black);
    --button-bg: var(--clr-promo02-300);
    --button-color--hover: var(--clr-black);
    --button-bg--hover: var(--clr-promo02-300);
    --button-border: transparent;
    --button-border--hover: transparent;
}
#lp-blackdays-2024 #pb23-fixedTag .pb23-btn.dark:hover {
    opacity: 0.8;
}
#lp-blackdays-2024 #pb23-fixedTag .pb23-btn.dark svg path {
    fill: transparent !important;
}
#lp-blackdays-2024 #header {
    padding: 60px 0 40px;
    text-align: center;
    background: var(--clr-gray-700);
}
#lp-blackdays-2024 #header h1 {
    margin: 0 auto 20px;
}
#lp-blackdays-2024 #header h1 > img {
    width: 100%;
    max-width: 444px;
}
#lp-blackdays-2024 #header .subtitle {
    display: block;
    margin: 0 0 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--clr-white);
}
#lp-blackdays-2024 #header .content {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--clr-white);
}
#lp-blackdays-2024 #categories {
    padding: 0 0 56px;
    background: var(--clr-gray-700);
}
#lp-blackdays-2024 #inspiration {
    padding: 20px 0 60px;
}
#lp-blackdays-2024 #inspiration header > * {
    text-align: center;
}
#lp-blackdays-2024 #listproducts {
    padding: 60px 0 0;
}
#lp-blackdays-2024 .pb23-carousel--products-leads {
    margin-bottom: 40px;
}
#lp-blackdays-2024 #banner01,
#lp-blackdays-2024 #banner02,
#lp-blackdays-2024 #banner03 {
    padding: 0px 0 60px;
}
#lp-blackdays-2024 #categories .pb23-banner,
#lp-blackdays-2024 #categories .pb23-banner * {
    --banner-cta--spacer: 20px;
}
#lp-blackdays-2024 #inspiration .pb23-banner,
#lp-blackdays-2024 #inspiration .pb23-banner * {
    --banner-cta--spacer: 20px;
    --banner-title--font: 1.5rem !important;
}
#lp-blackdays-2024 #banner01 .pb23-banner,
#lp-blackdays-2024 #banner02 .pb23-banner,
#lp-blackdays-2024 #banner03 .pb23-banner {
    transition: all 0.3s ease-out;
}
#lp-blackdays-2024 #banner01 .pb23-banner:hover,
#lp-blackdays-2024 #banner02 .pb23-banner:hover,
#lp-blackdays-2024 #banner03 .pb23-banner:hover {
    transform: scale(1.03);
}
/*
#lp-leads--202305[data-idlead="334"] .pb23-header--concept-content {
    background: var(--clr-gray-700);
}
#lp-leads--202305[data-idlead="334"] .pb23-header--concept-content .content * {
    color: var(--clr-white);
}
#lp-leads--202305[data-idlead="334"] .pb23-header--concept-content .cta .pb23-btn {
    color: var(--clr-black);
    background: var(--clr-promo02-400);
}*/
@media (max-width:992px) {
    #lp-blackdays-2024 .pb23-grid--banners-v2.template-0 .pb23-banner, 
    #lp-blackdays-2024 .pb23-grid--banners-v2.template-0 .pb23-banner * {
        --banner-height: 267px;
    }
    #lp-blackdays-2024 #inspiration .pb23-banner, #lp-blackdays-2024 #inspiration .pb23-banner * {
        --banner-title--font: 1.25rem !important;
    }
    #lp-blackdays-2024 #banner01 .pb23-banner,
    #lp-blackdays-2024 #banner01 .pb23-banner *,
    #lp-blackdays-2024 #banner02 .pb23-banner,
    #lp-blackdays-2024 #banner02 .pb23-banner *,
    #lp-blackdays-2024 #banner03 .pb23-banner,
    #lp-blackdays-2024 #banner03 .pb23-banner * {
        --banner-align--y: center;
    }
    #lp-blackdays-2024 #banner01 .pb23-banner .cta-wrapper,
    #lp-blackdays-2024 #banner02 .pb23-banner .cta-wrapper,
    #lp-blackdays-2024 #banner03 .pb23-banner .cta-wrapper {
        margin-top: 20px;
    }
}
/* PÁGINA OFFICINA */
body:has(#page-officina--main) {
    @media(max-width:769px) {
        overflow-y: hidden;
    }
}
#page-officina--main {
    .pb23-breadcrumbs {
        margin-top: 0px;
        padding-top: 40px;
        margin-bottom: 8px;
        @media (max-width: 1180px) {
            padding-top: 22px;
        }
    }   
    #about {
        margin-bottom: 60px;
        @media (max-width: 769px) {
            margin-bottom: 40px;
        }
        header {
            h1 {
                margin: 0 0 0.5rem;                
            }
        }
    }
    #concept {
        .pb23-video {
            height: 808px;
            margin-bottom: 60px;
            @media (max-width: 1180px) {
                height: 430px;
            }
            @media (max-width: 769px) {
                height: 373px;
                margin-bottom: 40px;
            }
            img {
                display: block;
                width: 100%;
            }
            img.mobile {
                display: none;
            }
            iframe.desktop,
            video.desktop,
            img.desktop {
                display: block;
            }
        }
    }
    #products {
        margin-bottom: 60px;
        @media (max-width: 769px) {
            margin-bottom: 40px;
        }
    }
    #collabs {
        .pb23-grid--banners,
        .pb23-grid--banners * {
            --banner-title--font: 1.5rem;
            --banner-subtitle--font: 1.125rem;
            --banner-subtitle--spacer: 8px;
            @media (max-width: 769px) {
                --banner-title--font: 1.25rem;
                --banner-subtitle--font: 1rem;
                --banner-cta--font: 0.75rem;
            }       
        }
        .pb23-banner {
            .content {
                .title {
                    font-weight: 300;
                }
                .subtitle {
                    text-transform: none;
                    font-weight: 500;
                }
            }
        }
    }
}
#page-officina--lines {
    .pb23-breadcrumbs {
        margin-top: 0px;
        padding-top: 40px;
        margin-bottom: 8px;
        @media (max-width: 1180px) {
            padding-top: 22px;
        }
    }   
    #about {
        margin-bottom: 40px;
        header {
            h1 {
                margin: 0 0 0.5rem;                
            }
        }
    }
}
#page-officina--line {
    .pb23-breadcrumbs {
        margin-top: 0px;
        padding-top: 40px;
        margin-bottom: 8px;
        @media (max-width: 1180px) {
            padding-top: 22px;
        }
    } 
    #header {
        margin-bottom: 32px;
        h1 {
            margin: 0;                
        }
        .pb23-header--line-aside .aside--wrapper {
            align-self: start !important;
        }
    }  
}



#Line_officinalines #lineslist
{
    margin-bottom: 60px;
}
#Lead_indexb {
    .pb23-header--concept-content {
        .pb23-btn, .pb23-btn * {
            --button-transform: uppercase !important;
        }
    }
}